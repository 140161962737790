
import {
    defineComponent, onMounted, reactive, WritableComputedRef,
} from 'vue';
import TextInput from '@/components/inputs/TextInput.vue';
import { Address, GoogleAddressParser } from '@/services/GoogleAddressParser';
import useModelWrapper from '@/composable/useModelWrapper';

type GooglePrediction = {
    description: string;
    place_id: string;
    matched_substrings: Array<{ length: number; offset: number; }>;
    reference: string;
    structured_formatting: Object
    terms: Array<{ offset: number; value: string; }>
    types: Array<string>;
}

type State = {
    results: Array<GooglePrediction>;
    service: any;
    isOpen: boolean;
    isSet: boolean;
    showMap: boolean;
}

export interface PlaceAutocompleteResult extends Address {
    utc_offset: string;
    lat: number;
    long: number;
}

// TODO: If we are going to use this feature, Orbis will need to get it's own api key

export default defineComponent({
    name: 'places-autocomplete',
    components: { TextInput },
    props: {
        modelValue: {
            type: String,
            default: '',
        },
        placeholder: String,
        label: String,
        cols: String,
        lg: String,
        md: String,
        sm: String,
        xs: String,
        loading: { type: Boolean, default: () => false },
        readonly: Boolean,
        error: String,
    },
    emits: ['onSelect'],
    setup(props, context) {
        const state = reactive<State>({
            results: [],
            service: null,
            isOpen: false,
            isSet: false,
            showMap: true,
        });

        const inputValue: WritableComputedRef<string | undefined> = useModelWrapper(props, context, 'modelValue');

        onMounted(() => {
            state.isOpen = false;
            state.results = [];
        });

        function mapsInit() {
            // not sure if this is the best way to use places api
            // @ts-ignore
            state.service = new window.google.maps.places.AutocompleteService();
        }

        mapsInit();

        function displaySuggestions(predictions: Array<GooglePrediction>, status: string) {
            // not sure if this is the best way to use places api
            // @ts-ignore
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                state.results = [];
                return;
            }
            state.results = predictions;
        }

        function onChange(val: string) {
            if (val.length > 0) {
                state.service.getPlacePredictions({
                    input: val,
                    types: ['street_address', 'premise', 'subpremise'],
                }, displaySuggestions);
                state.isOpen = true;
            } else {
                state.results = [];
                state.isOpen = false;
            }
            if (state.isSet) {
                state.isSet = false;
            }
            context.emit('onSelect', {});
        }

        function setResult(result: GooglePrediction) {
            // TODO: type out place
            function callback(place: any, status: string) {
                // @ts-ignore
                if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                    const address = new GoogleAddressParser(place.address_components).result();
                    context.emit('onSelect', {
                        ...address,
                    } as PlaceAutocompleteResult);
                }
            }

            // @ts-ignore
            // eslint-disable-next-line no-undef
            const placesService = new window.google.maps.places.PlacesService(map);
            placesService.getDetails({ placeId: result.place_id, fields: ['address_components'] }, callback);

            state.isOpen = false;
            state.isSet = true;
        }

        function handleClickOutside() {
            state.isOpen = false;
            state.isSet = true;
        }

        return {
            state,
            onChange,
            setResult,
            handleClickOutside,
            inputValue,
        };
    },
});
