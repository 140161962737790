const MasterDataRouteTypes = {
    BASE: 'master-data-base',
    DASHBOARD: 'master-data-dashboard',
    USER: {
        LIST: 'user-admin',
    },
    ROLES: {
        BASE: 'roles-base',
        LIST: 'roles-list',
        ADD: 'roles-add',
        EDIT: 'roles-edit',
    },
    ITEM: {
        BASE: 'master-data-item-base',
        LIST: 'master-data-item-list',
        EDIT: 'master-data-item-edit',
        ADD: 'master-data-item-add',
    },
    CARRIER: {
        LIST: 'master-data-carrier-list',
    },
    LOCATION: {
        BASE: 'master-data-location-base',
        LIST: 'master-data-location-list',
        EDIT: 'master-data-location-edit',
    },
    CONFIG: {
        BASE: 'master-data-config-base',
        ITEM_TYPE: 'master-data-config-item-types',
        LOCATION_TYPE: 'master-data-config-location-types',
        TRAILER_TYPE: 'master-data-config-trailer-types',
        FREIGHT_CODE: 'master-data-config-freight-codes',
        EQUIPMENT_TYPE: 'master-data-config-equipment-types',
    },
    TRANSACTION: {
        BASE: 'master-data-transaction-base',
        LIST: 'master-data-transaction-list',
        EDIT: 'master-data-transaction-edit',
        ADD_NEW_TRANSACTION: 'master-data-transaction-supplier-shipping',
        PLANNING: 'master-data-transaction-planning',
    },
    INVENTORY: {
        BASE: 'master-data-inventory-base',
        LIST: 'master-data-inventory-list',
        ADJUSTMENT_LIST: 'master-data-inventory-adjustment-list',
        ITEM_TRANSFORMATION: 'master-data-inventory-item-transformation',
    },
    CYCLE_COUNT_REPORT: {
        BASE: 'master-data-cycle-count-report-base',
        LIST: 'master-data-cycle-count-report-list',
        REPORT: 'master-data-cycle-count-report-report',
    },
    SHIPPING_PLANNER: 'master-data-shipping-planner',
    IMPORT_ERRORS: {
        BASE: 'master-data-import-errors',
        ASN: 'master-data-import-errors-advanced-shipping-notice',
        UPDATE_ASN: 'master-data-import-errors-update-advanced-shipping-notice',
        IDF: 'master-data-import-errors-incoming-demand-forecast',
        UPDATE_IDF: 'master-data-import-errors-update-incoming-demand-forecast',
        PFEP: 'master-data-import-errors-production-part-notice',
        UPDATE_PFEP: 'master-data-import-errors-update-production-part-notice',
        PO: 'master-data-import-errors-partner-order',
        UPDATE_PO: 'master-data-import-errors-update-partner-order',
    },
    HISTORY: {
        BASE: 'master-data-history',
        LIST: 'master-data-history-list',
    },
    PRODUCTION_PART: {
        LIST: 'master-data-production-part-list',
    },
    CUSTOMER_SETTINGS: {
        LIST: 'master-data-customer-settings-list',
    },
    EQUIPMENT: {
        LIST: 'master-data-equipment-list',
    },
    REPAIR_PART: {
        BASE: 'master-data-repair-part-base',
        LIST: 'master-data-repair-part-list',
        ADD: 'master-data-repair-part-add',
        EDIT: 'master-data-repair-part-edit',
    },
};

export default MasterDataRouteTypes;
