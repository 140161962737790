export enum CycleCountFrequency {
    None = 0,
    Daily = 10,
    Weekly = 20,
    Monthly = 30,
    Quarterly = 40,
}

// todo - this is more generic and can be used for any enum to dropdown mapping, consider moving to a generic utility area
const StringIsNumber = (value: any) => isNaN(Number(value)) === false;
export function ToOptionsList(enumme: any) {
    return Object.keys(enumme).filter(StringIsNumber).map((value) => {
        const id = Number(value);
        return { id, name: enumme[value] };
    });
}
