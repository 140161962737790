import { reactive } from 'vue';
import { store } from '@/decorators/store';
import { CoreStore } from '@/store/CoreStore';
import AdminSettingsDTO from '@/dtos/AdminSettingsDTO';

type State = {
    shipDateRangeStart: number;
    shipDateRangeEnd: number;
    dashboardUrl: string;
};

@store
export default class AdminSettingsStore {
    private rootStore: CoreStore;

    constructor(rootStore: CoreStore) {
        this.rootStore = rootStore;
    }

    private state = reactive<State>({
        shipDateRangeStart: -10,
        shipDateRangeEnd: 30,
        dashboardUrl: '',
    });

    public init(data: AdminSettingsDTO) {
        this.state.shipDateRangeStart = data.shipDateRangeStart;
        this.state.shipDateRangeEnd = data.shipDateRangeEnd;
        this.state.dashboardUrl = data.dashboardUrl;
    }

    get shipDateRangeStart(): number {
        return this.state.shipDateRangeStart;
    }

    get shipDateRangeEnd(): number {
        return this.state.shipDateRangeEnd;
    }

    get dashboardUrl(): string {
        return this.state.dashboardUrl;
    }
}
