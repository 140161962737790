import { hasPermission } from '@/services/permissions/Permissions';

export type PartnerEngagementPermission =
    'Order Packaging' | 'Add Shipment' | 'Receipt Confirmation' | 'PEM Record Count' | 'PEM Adjust Count' |
    'View Inventory' | 'PEM Production Parts' | 'Dashboard PEM';

const PARTNER_ENGAGEMENT_PERMISSIONS = {
    canAccessModule(): boolean {
        return hasPermission('Order Packaging')
            || hasPermission('Add Shipment')
            || hasPermission('Receipt Confirmation')
            || hasPermission('PEM Record Count')
            || hasPermission('View Inventory')
            || hasPermission('PEM Production Parts')
            || hasPermission('Dashboard PEM');
    },
    canOrderPackaging(): boolean {
        return hasPermission('Order Packaging');
    },
    canAddShipment(): boolean {
        return hasPermission('Add Shipment');
    },
    canConfirmDisputeInboundReceipts(): boolean {
        return hasPermission('Receipt Confirmation');
    },
    canRecordCount(): boolean {
        return hasPermission('PEM Record Count');
    },
    canAdjustCount(): boolean {
        return hasPermission('PEM Adjust Count');
    },
    canViewInventory(): boolean {
        return hasPermission('View Inventory');
    },
    canEditProductionPartData(): boolean {
        return hasPermission('PEM Production Parts');
    },
    canAccessDashboard(): boolean {
        return hasPermission('Dashboard PEM');
    },
};

export default PARTNER_ENGAGEMENT_PERMISSIONS;