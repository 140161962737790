import { CycleCountFrequency } from './CycleCountFrequency';

export default class CycleCountPreferences {
    constructor(init?: Partial<CycleCountPreferences>) {
        if (!init?.frequency) {
            this.frequency = CycleCountFrequency.None;
        }
        Object.assign(this, init);
    }

    public id!: number;

    public frequency!: CycleCountFrequency;

    public containersCount?: number | null = 0;

    public areNotificationsEnabled?: boolean;

    public countItemsUnderLoad: boolean = false;

    public creationDaysBeforeDue?: number;

    public inventoryAdjustmentThreshold?: number;

    get notificationDaysBeforeDue(): number | undefined {
        return this.creationDaysBeforeDue;
    }

    resetContainerCount() {
        this.containersCount = 0;
        this.countItemsUnderLoad = false;
    }

    public changeFrequency(frequency: CycleCountFrequency) {
        this.frequency = frequency;
        this.creationDaysBeforeDue = this.getCreationDaysBeforeByFrequency(frequency);
        if (frequency === CycleCountFrequency.None || frequency === CycleCountFrequency.Monthly || frequency === CycleCountFrequency.Quarterly) {
            this.containersCount = undefined;
        } else {
            this.resetContainerCount();
        }
        if (frequency === CycleCountFrequency.None || frequency === CycleCountFrequency.Daily) {
            this.areNotificationsEnabled = false;
        }
    }

    public getCreationDaysBeforeByFrequency(frequency: CycleCountFrequency): number | undefined {
        switch (frequency) {
        case CycleCountFrequency.None:
            return undefined;
        case CycleCountFrequency.Daily:
            return undefined;
        case CycleCountFrequency.Weekly:
            return 5;
        case CycleCountFrequency.Monthly:
            return 10;
        case CycleCountFrequency.Quarterly:
            return 30;
        default:
            return undefined;
        }
    }

    public toggleCountAllContainers() {
        if (this.containersCount === undefined || this.containersCount === null) {
            this.resetContainerCount();
        } else {
            this.containersCount = undefined;
        }
    }

    get inventoryAdjustmentThresholdPercent(): number {
        return (this.inventoryAdjustmentThreshold ?? 0) * 100;
    }

    set inventoryAdjustmentThresholdPercent(val: number) {
        this.inventoryAdjustmentThreshold = val / 100;
    }
}
