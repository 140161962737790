export type ButtonTranslation = {
    confirm: string;
    cancel: string;
    submit: string;
    filter: string;
    reset: string;
    edit: string;
    view: string;
    cancelOrder: string;
    addShipment: string;
    addNewShipment: string;
    orderPackaging: string;
    save: string;
    shipNow: string;
    startDispute: string;
    cancelDispute: string;
    submitDispute: string;
    addItem: string;
    submitCount: string;
    closeAsIs: string;
    closeWithAdjustments: string;
    addPlannedPart: string;
    addProductionPart: string;
    editPlannedPart: string;
    editProductionPart: string;
    addUser: string;
    showHistory: string;
    continue: string;
    clickHere: string;
    ok: string;
    saveAndExit: string;
    saveAndEstimate: string;
    saveAndSort: string;
    saveAndPutAway: string;
    saveAndPrint: string;
    completeLoad: string;
    revertLoad: string;
    remove: string;
    add: string;
    addStop: string;
    addItems: string;
    moveToYard: string;
    shippingActions: string;
    addNew: string;
    addExisting: string;
    uploadImage: string;
    uploadImages: string;
    seeData: string;
    newSearch: string;
    addAddress: string;
    addCarrier: string;
    addDock: string;
    addEquipment: string;
    addFreightCode: string;
    addItemType: string;
    addLocation: string;
    addLocationType: string;
    addRoute: string;
    addSortStation: string;
    addTrailerType: string;
    all: string;
    createNew: string;
    delete: string;
    deleteTransaction: string;
    deleteUser: string;
    printBillOfLading: string;
    printInboundReceipt: string;
    printPackList: string;
    printPickTicket: string;
    printProforma: string;
    reject: string;
    revalidate: string;
    revalidateAll: string;
    selectAll: string;
    use: string;
    updateData: string;
    addRole: string;
    editRole: string;
    addEquipmentType: string;
    closeTrailer: string;
    submitRejectionReport: string;
    unloadComplete: string;
    resetPassword: string;
    addRepair: string;
    addRepairPart: string;
    allocateTransaction: string;
    allocateSelectedTransactions: string;
    finalizeCounts: string;
    allTransactions: string;
    reopen: string;
    removeLateFlag: string;
    updateLateReason: string;
    removeTrailerArrivedFlag: string;
    updateTrailerNumber: string;
    addFloorLocation: string;
};

const buttonEn: ButtonTranslation = {
    confirm: 'confirm',
    cancel: 'cancel',
    submit: 'submit',
    filter: 'filter',
    reset: 'reset',
    edit: 'edit',
    view: 'view',
    cancelOrder: 'cancel order',
    addShipment: 'add shipment',
    addNewShipment: 'add new shipment',
    orderPackaging: 'order packaging',
    save: 'save',
    shipNow: 'ship now',
    startDispute: 'start dispute',
    cancelDispute: 'cancel dispute',
    submitDispute: 'submit dispute',
    addItem: 'add item',
    submitCount: 'submit count',
    closeAsIs: 'close as is',
    closeWithAdjustments: 'close with adjustments',
    addPlannedPart: 'Add PFEP',
    addProductionPart: 'add production part',
    editPlannedPart: 'Edit PFEP',
    editProductionPart: 'edit production part',
    addUser: 'add user',
    showHistory: 'show history',
    continue: 'continue',
    clickHere: 'click here',
    ok: 'ok',
    saveAndExit: 'save & exit',
    saveAndEstimate: 'save & estimate',
    saveAndSort: 'save & sort',
    saveAndPutAway: 'save & put away',
    saveAndPrint: 'save & print',
    completeLoad: 'complete load',
    revertLoad: 'revert load',
    remove: 'remove',
    add: 'add',
    addStop: 'add stop',
    addItems: 'add items',
    moveToYard: 'move to yard',
    shippingActions: 'shipping actions',
    addNew: 'add new',
    addExisting: 'add existing',
    uploadImage: 'upload image',
    uploadImages: 'upload images',
    seeData: 'see data',
    newSearch: 'new search',
    addAddress: 'add address',
    addCarrier: 'add carrier',
    addDock: 'add dock',
    addEquipment: 'add equipment',
    addFreightCode: 'add freight code',
    addItemType: 'add item type',
    addLocation: 'add location',
    addLocationType: 'add location type',
    addRoute: 'add route',
    addSortStation: 'add sort station',
    addTrailerType: 'add trailer type',
    all: 'all',
    createNew: 'create new',
    delete: 'delete',
    deleteTransaction: 'delete transaction',
    deleteUser: 'delete user',
    printBillOfLading: 'print bill of lading',
    printInboundReceipt: 'print inbound receipt',
    printPackList: 'print pack list',
    printPickTicket: 'print pick ticket',
    printProforma: 'print pro-forma',
    reject: 'reject',
    revalidate: 'revalidate',
    revalidateAll: 'revalidate all',
    selectAll: 'select all',
    use: 'use',
    updateData: 'update data',
    addRole: 'add role',
    editRole: 'edit role',
    addEquipmentType: 'add equipment type',
    closeTrailer: 'close trailer',
    submitRejectionReport: 'submit rejection report',
    unloadComplete: 'unload complete',
    resetPassword: 'reset password',
    addRepair: 'add repair',
    addRepairPart: 'add repair part',
    allocateTransaction: 'allocate transaction',
    allocateSelectedTransactions: 'allocate selected transactions',
    finalizeCounts: 'finalize counts',
    allTransactions: 'All Transactions',
    reopen: 'Re-Open',
    removeLateFlag: 'Remove Late Flag',
    updateLateReason: 'Update Late Reason',
    removeTrailerArrivedFlag: 'Remove Trailer Arrived Flag',
    updateTrailerNumber: 'Update Trailer Number',
    addFloorLocation: 'add floor location',
};

const buttonEs: ButtonTranslation = {
    confirm: 'confirmar',
    cancel: 'cancelar',
    submit: 'enviar',
    filter: 'filtrar',
    reset: 'reiniciar',
    edit: 'editar',
    view: 'ver',
    cancelOrder: 'cancelar orden',
    addShipment: 'añadir envío',
    addNewShipment: 'añadir nuevo envío',
    orderPackaging: 'orden de embalaje',
    save: 'ahorrar',
    shipNow: 'enviar ahora',
    startDispute: 'iniciar disputa',
    cancelDispute: 'cancelar disputa',
    submitDispute: 'enviar disputa',
    addItem: 'añadir artículo',
    submitCount: 'enviar recuento',
    closeAsIs: 'cercano como es',
    closeWithAdjustments: 'cerrar con ajustes',
    addPlannedPart: 'Añadir PFEP',
    addProductionPart: 'añadir parte de producción',
    editPlannedPart: 'Editar PFEP',
    editProductionPart: 'editar parte de producción',
    addUser: 'agregar usuario',
    showHistory: 'mostrar historial',
    continue: 'continuar',
    clickHere: 'haga clic aquí',
    ok: 'de acuerdo',
    saveAndExit: 'guardar y salir',
    saveAndEstimate: 'guardar y estimar',
    saveAndSort: 'guardar y clasificar',
    saveAndPutAway: 'guardar y poner influencia',
    saveAndPrint: 'guardar e imprimir',
    completeLoad: 'carga completa',
    revertLoad: 'revertir carga',
    remove: 'retirar',
    add: 'agregar',
    addStop: 'agregar parada',
    addItems: 'agregar artículos',
    moveToYard: 'mover al patio',
    shippingActions: 'acciones de envío',
    addNew: 'añadir nueva/o',
    addExisting: 'añadir existente',
    uploadImage: 'cargar imagen',
    uploadImages: 'cargar imágenes',
    seeData: 'ver datos',
    newSearch: 'nueva búsqueda',
    addAddress: 'añadir dirección',
    addCarrier: 'añadir transportista',
    addDock: 'añadir muelle',
    addEquipment: 'añadir equipo',
    addFreightCode: 'añadir código de flete',
    addItemType: 'añadir tipo de artículo',
    addLocation: 'agregar direccion',
    addLocationType: 'añadir tipo de ubicación',
    addRoute: 'añadir ruta',
    addSortStation: 'añadir estación de clasificación',
    addTrailerType: 'añadir tipo de trailer',
    all: 'todos',
    createNew: 'crear nuevo',
    delete: 'eliminar',
    deleteTransaction: 'eliminar transacción',
    deleteUser: 'borrar usuario',
    printBillOfLading: 'imprimir conocimiento de embarque',
    printInboundReceipt: 'imprimir recibo de entrada',
    printPackList: 'imprimir lista de paquetes',
    printPickTicket: 'imprimir billete de recogida',
    printProforma: 'imprimir pro forma',
    reject: 'rechazar',
    revalidate: 'revalidar',
    revalidateAll: 'revalidar todo',
    selectAll: 'seleccionar todo',
    use: 'usar',
    updateData: 'actualizar datos',
    addRole: 'agregar rol',
    editRole: 'editar rol',
    addEquipmentType: 'añadir tipo de equipo',
    closeTrailer: 'cerrar remolque',
    submitRejectionReport: 'enviar informe de rechazo',
    unloadComplete: 'descarga completa',
    resetPassword: 'restablecer la contraseña',
    addRepair: 'agregar reparación',
    addRepairPart: 'añadir pieza de reparación',
    allocateTransaction: 'asignar transacción',
    allocateSelectedTransactions: 'asignar transacciones seleccionadas',
    finalizeCounts: 'finalizar conteos',
    allTransactions: 'Trans (Todas)',
    reopen: 'reabrir',
    removeLateFlag: 'Eliminar bandera de retraso',
    updateLateReason: 'Actualizar motivo de retraso',
    removeTrailerArrivedFlag: 'Eliminar bandera de llegada del remolque',
    updateTrailerNumber: 'Actualizar número de remolque',
    addFloorLocation: 'Agregar ubicación del piso',
};

export { buttonEn, buttonEs };
