export default {
    BASE: 'flootrak-base',
    HOME: 'floortrak-home',
    RECEIVING: {
        BASE: 'floortrak-receiving-base',
        NEW: 'floortrak-receiving-new',
        EXISTING: 'floortrak-receiving-existing',
        ESTIMATE: 'floortrak-receiving-estimate',
        SORT: 'floortrak-receiving-sort',
        PUT_AWAY: 'floortrak-receiving-putaway',
        CLOSEOUT: 'floortrak-receiving-closeout',
    },
    SHIPPING: {
        BASE: 'floortrak-shipping-base',
        SEARCH: 'floortrak-shipping-search',
        EXISTING: 'floortrak-shipping-existing',
        NEW: 'floortrak-shipping-new',
        RESUME: 'floortrak-shipping-resume',
    },
    CYCLE_COUNT_REPORT: {
        REPORT: 'floortrak-engagement-cycle-count-report-report',
    },
    INVENTORY: {
        BASE: 'floortrak-inventory-base',
        ADD_TAG: 'floortrak-inventory-add-tag',
        REPLACE_TAG: 'floortrak-inventory-replace-tag',
        TRANSFER: 'floortrak-inventory-transfer',
        COMBINED_ITEMS: 'floortrak-inventory-combined-items',
        DECONSTRUCT_ITEMS: 'floortrak-inventory-deconstruct-items',
        IN_HOUSE_REPAIRS: 'floortrak-inventory-in-house-repairs',
        PUT_AWAY: 'floortrak-inventory-put-away',
    },
};
