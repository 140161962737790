import CycleCountPreferences from './cyclecount/CycleCountPreference';

export default class LocationType {
    constructor(init?: Partial<LocationType>) {
        Object.assign(this, init);
        this.cycleCountPreferences = new CycleCountPreferences(init?.cycleCountPreferences);
    }

    public id!: number;

    public description: string = '';

    public deleted: boolean = false;

    public cycleCountPreferencesId?: number;

    public staticTypeIdentifier?: StaticLocationType;

    public cycleCountPreferences?: CycleCountPreferences;

    public includeInPushPlanning: boolean = false;

    public toString() {
        return this.description;
    }
}

export enum StaticLocationType {
    ServiceCenter = 'ServiceCenter',
    Supplier = 'Supplier',
    Plant = 'Plant',
    BuiltIn = 'BuiltIn',
    ManagedWarehouse = 'ManagedWarehouse',
}
